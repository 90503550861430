import React, { useCallback } from 'react';
import { IonText } from '@ionic/react';
import './DropZone.css';

import { useDropzone } from 'react-dropzone';

interface ContainerProps {
  onDropCallback: Function;
}

const DropZone: React.FC<ContainerProps> = ({ onDropCallback }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onDropCallback(acceptedFiles);
    },
    [onDropCallback]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      className={`dropZone ion-margin ion-padding ${
        isDragActive ? 'dropZoneDragActive' : 'dropZoneDragInactive'
      }`}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <img
        alt="AnyDrop logo"
        className={`dropZoneImage ${
          isDragActive ? 'dropZoneImageDragActive' : 'dropZoneImageDragInactive'
        }`}
        src="assets/images/logo.svg"
      />
      <IonText color={`${isDragActive ? 'primary' : 'medium'}`}>
        <p>
          {isDragActive
            ? 'Drop the file'
            : 'Drag a file or click to select a file'}
        </p>
      </IonText>
    </div>
  );
};

export default DropZone;
