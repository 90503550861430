import React, { useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { cog } from 'ionicons/icons';
import DropZone from '../components/DropZone';
import NoConnection from '../components/NoConnection';
import FileTransferModal from '../components/FileTransferModal';
import IncomingFileTransferRequestAlert from '../components/IncomingFileTransferRequestAlert';
import AboutModal from '../components/AboutModal';
import SettingsActionSheet from '../components/SettingsActionSheet';
import ClientInfo from '../components/ClientInfo';
import './Main.css';

import { useAnyDrop } from '../hooks/useAnyDrop';

const Main: React.FC = () => {
  const [showSettingsActionSheet, setShowSettingsActionSheet] = useState<
    boolean
  >(false);
  const [showAboutModal, setShowAboutModal] = useState<boolean>(false);

  const {
    clientID,
    users,
    connectionError,
    files,
    setFiles,
    clearFiles,
    userStatuses,
    incomingFileTransferRequest,
    acceptIncomingFileTransferRequest,
    declineIncomingFileTransferRequest,
    clientStatus,
    sendFileTransferRequest,
  } = useAnyDrop();

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle>AnyDrop</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowSettingsActionSheet(true)}>
              <IonIcon slot="icon-only" icon={cog} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {clientID ? (
          <div className="content">
            <DropZone onDropCallback={(files: File[]) => setFiles(files)} />
            <ClientInfo id={clientID} status={clientStatus} />
          </div>
        ) : (
          <NoConnection connectionError={connectionError} />
        )}
        <FileTransferModal
          clientID={clientID}
          files={files}
          users={users}
          usersActive={userStatuses}
          onSendFileTransferRequest={sendFileTransferRequest}
          onClearFiles={clearFiles}
        />
        <SettingsActionSheet
          show={showSettingsActionSheet}
          onHide={() => setShowSettingsActionSheet(false)}
          onShowAboutModal={() => setShowAboutModal(true)}
        />
        <AboutModal
          show={showAboutModal}
          onHide={() => setShowAboutModal(false)}
        />
        <IncomingFileTransferRequestAlert
          request={incomingFileTransferRequest}
          onAccept={acceptIncomingFileTransferRequest}
          onDecline={declineIncomingFileTransferRequest}
        />
      </IonContent>
    </IonPage>
  );
};

export default Main;
