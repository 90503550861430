import { useRef, useEffect, useState } from 'react';
import { isPlatform } from '@ionic/react';
import { useFilesystem } from '@ionic/react-hooks/filesystem';
import { FilesystemDirectory } from '@capacitor/core';

import io from 'socket.io-client';
import Peer from 'simple-peer';
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';

import FileTransferRequest from '../models/FileTransferRequest';
import ReceivedFile from '../models/ReceivedFile';
import {
  addArrayBufferFallback,
  isJson,
  blobToBase64,
  errorToString,
} from '../utils/Helpers';

export function useAnyDrop() {
  const socket = useRef<SocketIOClient.Socket>();

  const [clientID, setClientID] = useState<string>('');
  const [users, setUsers] = useState<string[]>([]);
  const [connectionError, setConnectionError] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [userStatuses, setUserStatuses] = useState<Object>({});
  const [incomingFileTransferRequest, setIncomingFileTransferRequest] =
    useState<FileTransferRequest>();
  const [clientStatus, setClientStatus] = useState<string>('');

  const { writeFile } = useFilesystem();

  useEffect(() => {
    addArrayBufferFallback();

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      socket.current = io.connect('http://localhost:8000');
    } else {
      socket.current = io.connect('https://server.anydrop.io');
    }

    socket.current.on('connect', () => {
      setClientID(socket.current!.id);
      setConnectionError(false);
    });

    socket.current.on('connect_error', () => {
      setConnectionError(true);
    });

    socket.current.on('disconnect', () => {
      setClientID('');
    });

    socket.current.on('user_connected', (users: any) => {
      setUsers(users);
    });

    socket.current.on('user_disconnected', (users: any) => {
      setUsers(users);
    });

    socket.current!.on(
      'receiveFileTransferRequest',
      (request: FileTransferRequest) => {
        if (incomingFileTransferRequest === undefined)
          setIncomingFileTransferRequest(request);
        else declineFileTransferRequest(request);
      }
    );

    return () => {
      socket.current!.disconnect();
    };
    // eslint-disable-next-line
  }, []);

  const sendFileTransferRequest = (userID: string) => {
    setUserStatuses((userStatuses: any) => ({
      ...userStatuses,
      [userID]: 'Sending request',
    }));

    const peer = new Peer({
      initiator: true,
      trickle: false,
      config: {
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' },
          { urls: 'stun:global.stun.twilio.com:3478' },
        ],
      },
    });

    peer.on('error', (err: any) => {
      console.error(err);
      setUserStatuses((userStatuses: any) => ({
        ...userStatuses,
        [userID]: errorToString(err.code),
      }));
    });

    const UUID = uuidv4();

    peer.on('signal', (signal) => {
      const request = new FileTransferRequest(
        UUID,
        userID,
        signal,
        files!.map((file) => file.name),
        files!.map((file) => file.size)
      );
      socket.current!.emit('sendFileTransferRequest', request);
    });

    const handler = (signal: any, uuid: string) => {
      if (uuid === UUID) {
        socket.current!.off('fileTransferRequestAccepted', handler);

        setUserStatuses((userStatuses: any) => ({
          ...userStatuses,
          [userID]: 'Connecting',
        }));
        peer.signal(signal);

        peer.on('connect', () => {
          peer.send('{"total": "' + files.length + '"}');
          files.forEach((file, index) => {
            setUserStatuses((userStatuses: any) => ({
              ...userStatuses,
              [userID]: 'Sending file ' + (index + 1) + ' of ' + files.length,
            }));
            file.arrayBuffer().then((buffer) => {
              const chunkSize = 16 * 1024;

              while (buffer.byteLength) {
                const chunk = buffer.slice(0, chunkSize);
                buffer = buffer.slice(chunkSize, buffer.byteLength);

                peer.send(chunk);
              }

              peer.send(
                '{"index": ' + index + ', "name": "' + file.name + '"}'
              );
            });
          });
        });

        peer.on('data', (data) => {
          if (isJson(data.toString())) {
            const message = JSON.parse(data.toString());
            if (message.status)
              setUserStatuses((userStatuses: any) => ({
                ...userStatuses,
                [userID]: message.status,
              }));
          }
        });
      }
    };

    socket.current!.on('fileTransferRequestAccepted', handler);

    socket.current!.on('fileTransferRequestDeclined', () => {
      setUserStatuses((userStatuses: any) => ({
        ...userStatuses,
        [userID]: 'Declined',
      }));
    });
  };

  const acceptIncomingFileTransferRequest = () => {
    setClientStatus('Connecting');
    const peer = new Peer({
      trickle: false,
      config: {
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' },
          { urls: 'stun:global.stun.twilio.com:3478' },
        ],
      },
    });

    peer.on('error', (err: any) => {
      console.error(err);
      setClientStatus(errorToString(err.code));
    });

    peer.on('signal', (signal) => {
      socket.current!.emit('acceptIncomingFileTransferRequest', {
        uuid: incomingFileTransferRequest!.uuid,
        userID: incomingFileTransferRequest!.userID,
        signal: signal,
      });
    });

    peer.signal(incomingFileTransferRequest!.signal);

    setIncomingFileTransferRequest(undefined);

    let chunks: any[] = [];
    let total: number = 0;
    let receivedFiles: ReceivedFile[] = [];

    peer.on('data', async (data) => {
      if (isJson(data.toString())) {
        const metadata = JSON.parse(data.toString());
        if (metadata.total) {
          total = parseInt(metadata.total);
          setClientStatus('Receiving file 1 of ' + total);
        } else {
          const number = parseInt(metadata.index) + 1;
          const file = new Blob(chunks);
          // Download the file
          if (isPlatform('hybrid')) {
            const receivedFile = new ReceivedFile(file, metadata.name);
            receivedFiles.push(receivedFile);
          } else saveAs(file, metadata.name);
          if (number === total) {
            peer.send('{"status": "Files sent"}');
            peer.destroy();
            setClientStatus('Files received');
            if (isPlatform('hybrid')) {
              for (const receivedFile of receivedFiles) {
                const receivedFileBase64 = await blobToBase64(
                  receivedFile.file
                );
                await writeFile({
                  path: receivedFile.name,
                  data: receivedFileBase64 as string,
                  directory: FilesystemDirectory.Documents,
                });
              }
            }
          } else {
            chunks = [];
            setClientStatus('Receiving file ' + (number + 1) + ' of ' + total);
          }
        }
      } else {
        chunks.push(data);
      }
    });
  };

  const declineIncomingFileTransferRequest = () => {
    declineFileTransferRequest(incomingFileTransferRequest!);
    setIncomingFileTransferRequest(undefined);
  };

  const declineFileTransferRequest = (request: FileTransferRequest) => {
    socket.current!.emit('declineIncomingFileTransferRequest', {
      uuid: request.uuid,
      userID: request.userID,
    });
  };

  const clearFiles = () => {
    setFiles([]);
    setUserStatuses({});
  };

  return {
    clientID,
    users,
    connectionError,
    files,
    setFiles,
    clearFiles,
    userStatuses,
    incomingFileTransferRequest,
    acceptIncomingFileTransferRequest,
    declineIncomingFileTransferRequest,
    clientStatus,
    sendFileTransferRequest,
  };
}
