export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function isJson(string: string) {
  if (typeof string !== 'string') return false;
  try {
    const result = JSON.parse(string);
    const type = Object.prototype.toString.call(result);
    return type === '[object Object]' || type === '[object Array]';
  } catch (err) {
    return false;
  }
}

export function addArrayBufferFallback() {
  if ('File' in window.self) {
    File.prototype.arrayBuffer =
      File.prototype.arrayBuffer || customArrayBuffer;
  }
  Blob.prototype.arrayBuffer = Blob.prototype.arrayBuffer || customArrayBuffer;

  function customArrayBuffer(this: File | Blob) {
    return new Promise((resolve) => {
      let fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.readAsArrayBuffer(this);
    });
  }
}

export function blobToBase64(blob: Blob) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      resolve(event.target!.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
    fileReader.readAsDataURL(blob);
  });
}

export function errorToString(error: string) {
  switch (error) {
    case 'ERR_CONNECTION_FAILURE':
    case 'ERR_ICE_CONNECTION_FAILURE':
    case 'ERR_ICE_CONNECTION_CLOSED':
      return 'Connection failed';
    case 'ERR_WEBRTC_SUPPORT':
      return 'Unsupported browser';
    default:
      return 'Unknown error';
  }
}
