import React from 'react';
import { IonAvatar, IonText } from '@ionic/react';
import './ClientInfo.css';

interface ContainerProps {
  id: string;
  status: string;
}

const ClientInfo: React.FC<ContainerProps> = ({ id, status }) => {
  return (
    <div className="clientInfo ion-margin">
      <IonAvatar className="clientInfoAvatar">
        <img alt={`Avatar of user ${id}`} src="assets/images/avatar.png" />
      </IonAvatar>
      <IonText color="primary" className="ion-text-center">
        <p>{id}</p>
        <p>{status}</p>
      </IonText>
    </div>
  );
};

export default ClientInfo;
