import React from 'react';
import { information, close } from 'ionicons/icons';
import { IonActionSheet } from '@ionic/react';

interface ContainerProps {
  show: boolean;
  onHide: Function;
  onShowAboutModal: Function;
}

const SettingsActionSheet: React.FC<ContainerProps> = ({
  show,
  onHide,
  onShowAboutModal,
}) => {
  return (
    <IonActionSheet
      isOpen={show}
      onDidDismiss={() => onHide()}
      buttons={[
        {
          text: 'About',
          role: 'destructive',
          icon: information,
          handler: () => {
            onShowAboutModal();
          },
        },
        {
          text: 'Close',
          icon: close,
          role: 'cancel',
        },
      ]}
    />
  );
};

export default SettingsActionSheet;
