import React from 'react';
import { IonAvatar, IonText } from '@ionic/react';
import './UserInfo.css';

interface ContainerProps {
  id: string;
  status: string;
  onSelect: Function;
}

const UserInfo: React.FC<ContainerProps> = ({ id, status, onSelect }) => {
  return (
    <div
      className={`userInfo ${status ? 'userInfoActive' : 'userInfoInactive'}`}
      onClick={() => {
        if (!status) onSelect(id);
      }}
    >
      <IonAvatar className="userInfoAvatar">
        <img alt={`Avatar of user ${id}`} src="assets/images/avatar.png" />
      </IonAvatar>
      <IonText color="primary" className="ion-text-center">
        <p>{id}</p>
        <p>{status}</p>
      </IonText>
    </div>
  );
};

export default UserInfo;
