import React from 'react';
import { IonIcon, IonText } from '@ionic/react';
import { alertCircle } from 'ionicons/icons';
import './NoConnection.css';

interface ContainerProps {
  connectionError: boolean;
}

const ClientInfo: React.FC<ContainerProps> = ({ connectionError }) => {
  return (
    <div className="noConnection ion-text-center">
      {connectionError ? (
        <div>
          <IonIcon size="large" icon={alertCircle} />
          <p>There was an error connecting to the AnyDrop server.</p>
          <IonText color="medium">
            <p>
              Please make sure you're connected to the internet or try again
              later.
            </p>
          </IonText>
        </div>
      ) : (
        <div>
          <img
            alt="AnyDrop logo"
            className="noConnectionConnectingLogo"
            src="assets/images/logo.svg"
          />
          <IonText color="medium">
            <p>Connecting...</p>
          </IonText>
        </div>
      )}
    </div>
  );
};

export default ClientInfo;
