import React from 'react';
import { IonIcon, IonText } from '@ionic/react';
import { wifi } from 'ionicons/icons';
import './NoDevices.css';

const NoDevices: React.FC = () => {
  return (
    <div className="noDevices ion-text-center">
      <IonIcon size="large" icon={wifi} />
      <p>There are currently no other devices connected to AnyDrop.</p>
      <IonText color="medium">
        <p>Please make sure you're on the same network.</p>
      </IonText>
    </div>
  );
};

export default NoDevices;
