import React from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonRow,
  IonCol,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import UserInfo from '../components/UserInfo';
import NoDevices from './NoDevices';

import { formatBytes } from '../utils/Helpers';

interface ContainerProps {
  clientID: string;
  files: File[];
  users: string[];
  usersActive: any;
  onSendFileTransferRequest: Function;
  onClearFiles: Function;
}

const AboutModal: React.FC<ContainerProps> = ({
  clientID,
  files,
  users,
  usersActive,
  onSendFileTransferRequest,
  onClearFiles,
}) => {
  return (
    <IonModal
      isOpen={Boolean(clientID) && files.length > 0}
      swipeToClose={true}
      onDidDismiss={() => onClearFiles()}
    >
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle>
            {files?.length} {files && files.length > 1 ? 'files' : 'file'} (
            {files &&
              formatBytes(
                files.map((file) => file.size).reduce((a, b) => a + b, 0)
              )}
            )
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onClearFiles()}>
              <IonIcon slot="icon-only" icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {users.filter((userID) => userID !== clientID).length > 0 ? (
          <IonRow className="ion-justify-content-evenly">
            {users
              .filter((userID) => userID !== clientID)
              .map((userID, index) => {
                return (
                  <IonCol key={index} className="user" size="4">
                    <UserInfo
                      id={userID}
                      status={usersActive[userID]}
                      onSelect={onSendFileTransferRequest}
                    />
                  </IonCol>
                );
              })}
          </IonRow>
        ) : (
          <NoDevices />
        )}
      </IonContent>
    </IonModal>
  );
};

export default AboutModal;
