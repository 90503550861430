import React from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonList,
  IonLabel,
  IonItem,
  IonText,
  IonItemDivider,
  IonItemGroup,
} from '@ionic/react';
import { close } from 'ionicons/icons';

interface ContainerProps {
  show: boolean;
  onHide: Function;
}

const AboutModal: React.FC<ContainerProps> = ({ show, onHide }) => {
  return (
    <IonModal isOpen={show} swipeToClose={true} onDidDismiss={() => onHide()}>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle>About</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onHide()}>
              <IonIcon slot="icon-only" icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Frequently asked questions</IonLabel>
            </IonItemDivider>
            <IonItem>
              <IonLabel className="ion-text-wrap">
                <IonText>
                  <h3>Do I need to be connected to the internet?</h3>
                </IonText>
                <p>
                  Yes. An internet connection is required to discover and
                  connect to other devices on your network.
                </p>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel className="ion-text-wrap">
                <IonText>
                  <h3>Does AnyDrop have access to my data?</h3>
                </IonText>
                <p>No. All data transfers are completely peer-to-peer.</p>
              </IonLabel>
            </IonItem>
          </IonItemGroup>
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default AboutModal;
