export default class FileTransferRequest {
  uuid: string;
  userID: string;
  signal: object;
  fileNames: string[];
  fileSizes: number[];
  constructor(
    uuid: string,
    userID: string,
    signal: object,
    fileNames: string[],
    fileSizes: number[]
  ) {
    this.uuid = uuid;
    this.userID = userID;
    this.signal = signal;
    this.fileNames = fileNames;
    this.fileSizes = fileSizes;
  }
}
