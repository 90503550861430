import React from 'react';
import { IonAlert } from '@ionic/react';

import FileTransferRequest from '../models/FileTransferRequest';

import { formatBytes } from '../utils/Helpers';

interface ContainerProps {
  request: FileTransferRequest | undefined;
  onAccept: Function;
  onDecline: Function;
}

const IncomingFileTransferRequestAlert: React.FC<ContainerProps> = ({
  request,
  onAccept,
  onDecline,
}) => {
  return (
    <IonAlert
      isOpen={Boolean(request)}
      header={request?.userID}
      message={
        'is sending you <strong>' +
        request?.fileNames.length +
        '</strong> ' +
        (request && request.fileNames.length > 1 ? 'files' : 'file') +
        ' of <strong>' +
        (request &&
          formatBytes(
            request!.fileSizes.reduce((a: number, b: number) => a + b)
          )) +
        '</strong>'
      }
      buttons={[
        {
          text: 'Decline',
          role: 'cancel',
          handler: () => onDecline(),
        },
        {
          text: 'Accept',
          handler: () => onAccept(),
        },
      ]}
    />
  );
};

export default IncomingFileTransferRequestAlert;
